import React from "react";
import { useState, useEffect } from "react";
import axios from "axios";
import {
  LineChart,
  Line,
  XAxis,
  YAxis,
  CartesianGrid,
  ResponsiveContainer,
} from "recharts";
import moment from "moment";
import DateTimePicker from 'react-datetime-picker';
import './weightloss.css'

export default (props) => {
  const [weights, setWeights] = useState([]);
  const [firstTime, setFirstTime] = useState(0);
  const [results, setResults] = useState({});

  const notBefore = new Date("June 1, 2023 12:00:00 PST")
  useEffect(() => {
    async function fetchData() {
      let options = {
        url: "/api/getWeights",
        method: "GET",
      };
      const results = await axios(options);
      setResults(results);
      setFirstTime(notBefore.valueOf());
      adjustChartData(results, notBefore);
    }
    fetchData();
  }, []);

  function adjustChartData(results, date) {
    setWeights(
      results.data
        .filter((item) => new Date(parseFloat(item.RowKey)) > date)
        .map((item) => {
          let newItem = item;
          newItem.date = parseFloat(item.RowKey) - date.valueOf();
          console.log(newItem.date);
          return newItem;
        })
    );
  }

  function onChange(e) {
    setFirstTime(e.valueOf());
    adjustChartData(results, e);
  }

  function putWeight() {
    let weight = parseFloat(document.getElementById("weight").value);
    let options = {
      url: "/api/putWeight",
      method: "POST",
      data: {
        weight: parseFloat(weight),
      },
    };
    axios(options);
    document.getElementById("weight").value = "";
    weights.push({ weight, date: new Date().valueOf() - firstTime });
    setWeights(weights);
  }

  function getTicks(weights) {
    let yDomain  =[
      Math.min(...weights.map((i) => i.weight)),
      Math.max(...weights.map((i) => i.weight)),
    ];
    
    let ticks = [];
    
    let a = Math.floor(yDomain[0] / 10) * 10
    while (a < yDomain[1])
    {
      ticks.push(a);
      a += 10;
    }
    ticks.push(a);
    return ticks;
  }

  return (
    <div>
      <ResponsiveContainer height={500} width="100%">
        <LineChart
          data={weights}
          margin={{
            top: 5,
            right: 30,
            left: 20,
            bottom: 5,
          }}
        >
          <CartesianGrid strokeDasharray="3 3" />
          <XAxis
            dataKey="date"
            name="Time"
            tickFormatter={(unixTime) =>
              moment(unixTime + firstTime).format("MMM Do")
            }
            scale="time"
            type="number"
            domain={[
              Math.min(...weights.map((i) => i.date)),
              Math.max(...weights.map((i) => i.date)),
            ]}
          />
          <YAxis
            dataKey="weight"
            interval={0}
            ticks={getTicks(weights)}
            domain={[
              Math.min(...weights.map((i) => i.weight)),
              Math.max(...weights.map((i) => i.weight)),
            ]}
          />
          <Line dataKey="weight"></Line>
        </LineChart>
      </ResponsiveContainer>

      <div>
        <input id="weight" style={{ color: "#FFF" }}></input>
        <button onClick={putWeight}>Add Weight</button>
      </div>
      <div>
        <DateTimePicker onChange={onChange} value={notBefore} />
      </div>
    </div>
  );
};
