import React, { useState } from "react";
import "./navbar.css";
// import SimcraftPawn from '../SimcraftPawn/SimcraftPawn';
import WeightLoss from "../WeightLoss/weightLoss";
import PSAElement from "../PSA/psa";

export default () => {
  let [state, setState] = useState(0);
  return (
    <div className="navbar-parent">
      <nav className="navbar">
        <ul className="navbar-nav">
          <li className="logo">
            <button className="nav-link">
              <span className="link-text">Seth's Website</span>
              <svg
                aria-hidden="true"
                focusable="false"
                data-prefix="fas"
                data-icon="coffee"
                className="svg-inline--fa fa-coffee fa-w-20"
                role="img"
                xmlns="http://www.w3.org/2000/svg"
                viewBox="0 0 640 512"
              >
                <path
                  fill="currentColor"
                  d="M192 384h192c53 0 96-43 96-96h32c70.6 0 128-57.4 128-128S582.6 32 512 32H120c-13.3 0-24 10.7-24 24v232c0 53 43 96 96 96zM512 96c35.3 0 64 28.7 64 64s-28.7 64-64 64h-32V96h32zm47.7 384H48.3c-47.6 0-61-64-36-64h583.3c25 0 11.8 64-35.9 64z"
                ></path>
              </svg>
            </button>
          </li>
          {/* <li className="nav-item">
                        <button className={state === 0 ? 'active nav-link' : 'nav-link'} onClick={() => setState(0)}>
                            <svg aria-hidden="true" focusable="false" data-prefix="far" data-icon="money-bill-alt" className="svg-inline--fa fa-money-bill-alt fa-w-20" role="img" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 640 512">
                                <path fill="currentColor" d="M320 144c-53.02 0-96 50.14-96 112 0 61.85 42.98 112 96 112 53 0 96-50.13 96-112 0-61.86-42.98-112-96-112zm40 168c0 4.42-3.58 8-8 8h-64c-4.42 0-8-3.58-8-8v-16c0-4.42 3.58-8 8-8h16v-55.44l-.47.31a7.992 7.992 0 0 1-11.09-2.22l-8.88-13.31a7.992 7.992 0 0 1 2.22-11.09l15.33-10.22a23.99 23.99 0 0 1 13.31-4.03H328c4.42 0 8 3.58 8 8v88h16c4.42 0 8 3.58 8 8v16zM608 64H32C14.33 64 0 78.33 0 96v320c0 17.67 14.33 32 32 32h576c17.67 0 32-14.33 32-32V96c0-17.67-14.33-32-32-32zm-16 272c-35.35 0-64 28.65-64 64H112c0-35.35-28.65-64-64-64V176c35.35 0 64-28.65 64-64h416c0 35.35 28.65 64 64 64v160z"></path></svg>
                            <span className="link-text">
                                Splitwise
                        </span>
                        </button>
                    </li> */}
          <li className="nav-item">
            <button
              className={state === 0 ? "active nav-link" : "nav-link"}
              onClick={() => setState(0)}
            >
              <svg
                aria-hidden="true"
                focusable="false"
                data-prefix="fas"
                data-icon="envelope"
                className="svg-inline--fa fa-envelope fa-w-16"
                role="img"
                xmlns="http://www.w3.org/2000/svg"
                viewBox="0 0 512 512"
              >
                <path
                  fill="currentColor"
                  d="M502.3 190.8c3.9-3.1 9.7-.2 9.7 4.7V400c0 26.5-21.5 48-48 48H48c-26.5 0-48-21.5-48-48V195.6c0-5 5.7-7.8 9.7-4.7 22.4 17.4 52.1 39.5 154.1 113.6 21.1 15.4 56.7 47.8 92.2 47.6 35.7.3 72-32.8 92.3-47.6 102-74.1 131.6-96.3 154-113.7zM256 320c23.2.4 56.6-29.2 73.4-41.4 132.7-96.3 142.8-104.7 173.4-128.7 5.8-4.5 9.2-11.5 9.2-18.9v-19c0-26.5-21.5-48-48-48H48C21.5 64 0 85.5 0 112v19c0 7.4 3.4 14.3 9.2 18.9 30.6 23.9 40.7 32.4 173.4 128.7 16.8 12.2 50.2 41.8 73.4 41.4z"
                ></path>
              </svg>
              <span className="link-text">PSA</span>
            </button>
          </li>
          <li className="nav-item">
            <button
              className={state === 1 ? "active nav-link" : "nav-link"}
              onClick={() => setState(1)}
            >
              <svg
                aria-hidden="true"
                focusable="false"
                data-prefix="fas"
                data-icon="weight"
                className="svg-inline--fa fa-weight fa-w-16"
                role="img"
                xmlns="http://www.w3.org/2000/svg"
                viewBox="0 0 512 512"
              >
                <path
                  fill="currentColor"
                  d="M448 64h-25.98C438.44 92.28 448 125.01 448 160c0 105.87-86.13 192-192 192S64 265.87 64 160c0-34.99 9.56-67.72 25.98-96H64C28.71 64 0 92.71 0 128v320c0 35.29 28.71 64 64 64h384c35.29 0 64-28.71 64-64V128c0-35.29-28.71-64-64-64zM256 320c88.37 0 160-71.63 160-160S344.37 0 256 0 96 71.63 96 160s71.63 160 160 160zm-.3-151.94l33.58-78.36c3.5-8.17 12.94-11.92 21.03-8.41 8.12 3.48 11.88 12.89 8.41 21l-33.67 78.55C291.73 188 296 197.45 296 208c0 22.09-17.91 40-40 40s-40-17.91-40-40c0-21.98 17.76-39.77 39.7-39.94z"
                ></path>
              </svg>
              <span className="link-text">WeightLoss</span>
            </button>
          </li>
          {/* <li className="nav-item">
            <button
              className={state === 2 ? "active nav-link" : "nav-link"}
              onClick={() => setState(2)}
            >
              <svg
                aria-hidden="true"
                focusable="false"
                data-prefix="fas"
                data-icon="book"
                className="svg-inline--fa fa-book fa-w-14"
                role="img"
                xmlns="http://www.w3.org/2000/svg"
                viewBox="0 0 448 512"
              >
                <path
                  fill="currentColor"
                  d="M448 360V24c0-13.3-10.7-24-24-24H96C43 0 0 43 0 96v320c0 53 43 96 96 96h328c13.3 0 24-10.7 24-24v-16c0-7.5-3.5-14.3-8.9-18.7-4.2-15.4-4.2-59.3 0-74.7 5.4-4.3 8.9-11.1 8.9-18.6zM128 134c0-3.3 2.7-6 6-6h212c3.3 0 6 2.7 6 6v20c0 3.3-2.7 6-6 6H134c-3.3 0-6-2.7-6-6v-20zm0 64c0-3.3 2.7-6 6-6h212c3.3 0 6 2.7 6 6v20c0 3.3-2.7 6-6 6H134c-3.3 0-6-2.7-6-6v-20zm253.4 250H96c-17.7 0-32-14.3-32-32 0-17.6 14.4-32 32-32h285.4c-1.9 17.1-1.9 46.9 0 64z"
                ></path>
              </svg>
              <span className="link-text">Blog</span>
            </button>
          </li> */}
        </ul>
      </nav>
      <main>
        {/* <div style={{ display: state !== 0 ? 'none' : '' }}>
                    <SimcraftPawn ></SimcraftPawn>
                </div> */}
        <div style={{ display: state !== 0 ? "none" : "" }}>
          <div className="titlepage">
            <h1>Seth's Random Website!</h1>
          </div>
        </div>
        <div style={{ display: state !== 1 ? "none" : "" }}>
          <WeightLoss></WeightLoss>
        </div>
        {/* <div style={{ display: state !== 2 ? "none" : "" }}>
          <PSAElement md={blog}></PSAElement>
        </div> */}
        {/* <div style={{ display: state !== 0 ? 'none' : '' }}>
                    <CardStack></CardStack>
                </div> */}
      </main>
    </div>
  );
};
